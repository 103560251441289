import React from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "design-system/utils";

// Define variants using class-variance-authority
const buttonVariants = cva("inline-flex items-center justify-center rounded-full transition-transform duration-200 ease-in-out focus:outline-none ", {
  variants: {
    size: {
      sm: "p-1 text-sm",
      md: "p-2 text-base",
      lg: "p-3 text-lg"
    },
    variant: {
      primary: "bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500",
      secondary: "bg-gray-500 text-white hover:bg-gray-600 focus:ring-gray-500",
      outline: "border border-gray-300 text-gray-700 hover:bg-gray-100 focus:ring-gray-300",
      ghost: "bg-transparent hover:bg-gray-100 focus:ring-gray-200"
    }
  },
  defaultVariants: {
    size: "md",
    variant: "primary"
  }
});
interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  icon: React.ReactNode;
  disabled?: boolean;
  className?: string; // Additional custom classes
}
const IconButton: React.FC<IconButtonProps> = ({
  icon,
  onClick,
  size,
  variant,
  disabled = false,
  className
}) => {
  return <button onClick={onClick} disabled={disabled} className={cn(buttonVariants({
    size,
    variant
  }), !disabled && "hover:scale-110 active:scale-95",
  // Enlarge on hover and shrink on press
  disabled && "cursor-not-allowed opacity-50", className // Allow for additional custom classes
  )} data-sentry-component="IconButton" data-sentry-source-file="IconButton.tsx">
      {icon}
    </button>;
};
export default IconButton;