// src/components/ListItem.tsx
import React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "design-system/utils";
export const textVariants = cva("text-base font-normal", {
  variants: {
    variant: {
      primary: "text-gray-900",
      secondary: "text-gray-500",
      link: "text-default-accent underline-offset-4  cursor-pointer",
      warning: "text-amber-500"
    },
    size: {
      xs: "text-xs",
      sm: "text-sm",
      md: "text-md",
      lg: "text-lg",
      xl: "text-xl",
      "2xl": "text-2xl",
      "3xl": "text-3xl",
      "4xl": "text-4xl"
    }
  },
  defaultVariants: {
    variant: "primary",
    size: "md"
  }
});
export interface TextProps extends React.HTMLAttributes<HTMLParagraphElement>, VariantProps<typeof textVariants> {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}
export const Text = React.forwardRef<HTMLParagraphElement, TextProps>(({
  id,
  children,
  className,
  href,
  ...props
}, ref) => {
  const Component = href ? "a" : "p";
  return <p ref={(ref as React.Ref<HTMLParagraphElement & HTMLAnchorElement>)} id={id} onClick={props.onClick} className={cn(textVariants(props), className)} {...props}>
        {children}
      </p>;
});
Text.displayName = "Text";