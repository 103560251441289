import { api } from "utils/api";

const login = (data) => api.post(`/login`, { data });

const otp_verify = (data) => api.post(`/otp`, data);

const signup = (data) =>
  api.post(`/account/signup`, data);

export default {
  login,
  otp_verify,
  signup,
};
