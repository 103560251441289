import React from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "design-system/utils";
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  icon?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  iconPosition?: "left" | "right"; // Allows icon to be on the left or right
  children: React.ReactNode;
  isLoading?: boolean;
  fullWidth?: boolean;
  htmlFor?: string;
  helperText?: string;
}

// Define variants using class-variance-authority
const buttonVariants = cva("flex items-center justify-center rounded-md font-medium transition-transform duration-200 ease-in-out focus:outline-none cursor-pointer", {
  variants: {
    size: {
      sm: "px-2 py-2 text-sm",
      md: "px-4 py-2 text-base",
      lg: "px-6 py-3 text-lg"
    },
    variant: {
      primary: "bg-default-accent px-[28px] text-white hover:bg-blue-600 focus:ring-blue-500",
      secondary: "bg-gray-500 text-white hover:bg-gray-600 focus:ring-gray-500",
      outline: "border border-default-accent text-default-accent hover:primary-100 focus:ring-default-accent",
      ghost: "bg-transparent hover:bg-gray-100 focus:ring-gray-200",
      link: "bg-transparent p-0"
    }
  },
  defaultVariants: {
    size: "md",
    variant: "primary"
  }
});
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  icon,
  onClick,
  size,
  variant,
  disabled = false,
  className,
  iconPosition = "left",
  children,
  isLoading,
  fullWidth = false,
  htmlFor,
  helperText,
  ...props
  // Default icon position is on the left
}, ref) => {
  const ButtonComponent = htmlFor ? "label" : "button";
  return <ButtonComponent
  // @ts-ignore
  ref={!htmlFor ? ref : undefined} onClick={onClick} disabled={disabled} className={cn(fullWidth && "w-full", buttonVariants({
    size,
    variant
  }), !disabled && "hover:scale-[1.01] active:scale-[0.98]",
  // Enlarge on hover and shrink on press
  disabled && "cursor-not-allowed opacity-50", className)} htmlFor={htmlFor} {...props}>
        {isLoading ? <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.964 7.964 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg> : <div className="flex flex-col items-center  gap-1">
          <div className="flex flex-row items-center">

            {icon && iconPosition === "left" && <span className="mr-2">{icon}</span> // Space between icon and text
        }
            {children}
            {icon && iconPosition === "right" && <span className="ml-2">{icon}</span>}
          </div>
            {helperText && <p className="text-xs font-light">{helperText}</p>}
          </div>}
      </ButtonComponent>;
});
export default Button;