import React from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { useState } from "react";
import { cn } from "design-system/utils";
type Props = {
  selectedIndex: number;
  onChangeIndex: (index: number) => void;
  tabLabels: string[]; // Array of tab labels
  Panels: React.ReactNode[];
  tabClassName?: string; // Optional className for tab
  tabListClassName?: string; // Optional className for tab list
  tabPanelsClassName?: string; // Optional className for tab panels
  tabListRightContent?: React.ReactNode;
};
const defaultTabStyle = `border-transparent border-b-2 text-sm 
  data-[selected]:border-black px-3 py-2 transition-colors focus-visible:outline-none`;
const Tabs = (props: Props) => {
  const {
    selectedIndex,
    onChangeIndex,
    tabLabels,
    Panels,
    tabListRightContent,
    tabClassName = defaultTabStyle,
    tabListClassName = "",
    // Optional, default styling for tab list
    tabPanelsClassName = "overflow-y-auto flex-1" // Optional, default styling for panels
  } = props;
  return <TabGroup selectedIndex={selectedIndex} onChange={onChangeIndex} data-sentry-element="TabGroup" data-sentry-component="Tabs" data-sentry-source-file="Tabs.tsx">
      <TabList className={cn(["border-b border-gray-300", tabListClassName])} data-sentry-element="TabList" data-sentry-source-file="Tabs.tsx">
        <div className="flex flex-row justify-between items-center">
          <div className="flex w-full flex-row items-center">
            {tabLabels.map((label, index) => <Tab key={index} className={cn([defaultTabStyle, tabClassName])}>
                {label}
              </Tab>)}
          </div>
          <div>{tabListRightContent}</div>
        </div>
      </TabList>
      <TabPanels className={cn(["overflow-y-auto flex-1", tabPanelsClassName])} data-sentry-element="TabPanels" data-sentry-source-file="Tabs.tsx">
        {Panels[selectedIndex]}
      </TabPanels>
    </TabGroup>;
};
export default Tabs;