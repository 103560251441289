import { Icon } from "@iconify/react";
import { FallbackProps } from "react-error-boundary";
const ErrorFallback = ({
  error,
  resetErrorBoundary
}: FallbackProps) => {
  return <div className="min-h-screen flex items-center justify-center bg-gray-50" data-sentry-component="ErrorFallback" data-sentry-source-file="ErrorFallback.tsx">
      <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-lg">
        <div className="flex flex-col items-center text-center">
          <Icon icon="solar:sad-square-bold-duotone" className="text-red-600 h-10 w-10 mb-4" data-sentry-element="Icon" data-sentry-source-file="ErrorFallback.tsx" />
          <h2 className="text-2xl font-bold text-red-600 mb-4">
            Oops! Something went wrong
          </h2>

          <div className="mb-4">
            <p className="text-gray-600">
              We apologize for the inconvenience. An unexpected error has
              occurred.
            </p>
          </div>

          {error.message && <div className="mb-4 p-3 bg-red-50 rounded-md">
              <p className="text-sm text-red-800 font-mono">{error.message}</p>
            </div>}

          <button onClick={resetErrorBoundary} className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors">
            Try Again
          </button>
        </div>
      </div>
    </div>;
};
export default ErrorFallback;