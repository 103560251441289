import { AnalyticsService } from "./analytics-interface";
import GoogleAnalytics from "./analytics-services";

class AnalyticsManager {
  private service: AnalyticsService;

  constructor(service: AnalyticsService) {
    this.service = service;
  }

  initialize(trackingId: string) {
    this.service.initialize(trackingId);
  }

  pageview(path: string) {
    this.service.pageview(path);
  }

  event(category: string, action: string, label?: string, value?: number) {
    this.service.event(category, action, label, value);
  }
}

const analyticsManager = new AnalyticsManager(new GoogleAnalytics());
export default analyticsManager;
