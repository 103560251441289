import { Navigate } from "react-router-dom";
import { PATH_DASHBOARD } from "./path";
import { usePageTracking } from "hooks/usePageTracking";
const PublicRouter = ({
  Element
}: {
  Element: React.ElementType;
}) => {
  usePageTracking();
  const token = localStorage.getItem("token");
  return token ? <Navigate to={PATH_DASHBOARD.app.dashboard} /> : <Element />;
};
export default PublicRouter;