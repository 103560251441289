import FeatureGuard from "components/FeatureGuard";
import ProtectedComponent from "components/RBAC/ProtectedComponent";
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

// Extract SidebarItem component for reusability
const SidebarItem = ({
  item,
  location,
  onNavigate,
  matchesCurrentRoute
}: any) => <FeatureGuard posthogKey={item.posthogKey} data-sentry-element="FeatureGuard" data-sentry-component="SidebarItem" data-sentry-source-file="SidebarItem.tsx">
    <ProtectedComponent exact={false} requiredPermissions={item.permissions} fallback={<></>} data-sentry-element="ProtectedComponent" data-sentry-source-file="SidebarItem.tsx">
      <li key={item.name}>
        <span onClick={onNavigate(item?.path)} className={classNames(matchesCurrentRoute(location, item?.path) ? "bg-default-accent text-white" : "text-white hover:text-white hover:bg-black/40", "group flex gap-x-4 rounded-lg p-3 md:p-4 text-sm tracking-widest leading-6 font-regular cursor-pointer transition-colors duration-200")}>
          {item.icon}
          <span className="hidden md:inline">{item.name}</span>
        </span>
      </li>
    </ProtectedComponent>
  </FeatureGuard>;
export default SidebarItem;