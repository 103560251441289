import { NullableBoolean } from "aws-sdk/clients/acm";
import { api } from "utils/api";
import { nullable } from "zod";

// Create-Order types
type CreateOrderRequest = {
  amount: number;
  productId: number;
  email: string;
};
type CreateOrderResponse = {
  transactionId: number | string;
  token: string;
  email: string;
};

// Verify-Order OTP types
type VerifyOrderOTPRequest = {
  pgTxnId: number | string | undefined;
  otp: string;
  email: string;
};

// Order status types
type OrderStatusRequest = {
  transactionId: number | string;
};
type OrderStatusResponse = {
  transactionId: number | string;
  status: "CREATED" | "PENDING" | "FAILED" | "TIMEOUT" | "PAID";
};

type VerifyOrderOtpResponse = {
  data: CreateOrderResponse;
};

const payments = {
  createOrder: function (data: CreateOrderRequest) {
    return api.post<CreateOrderRequest, CreateOrderResponse>(`/checkout`, {
      ...data,
    });
  },

  verifyOrderOTP: function (data: VerifyOrderOTPRequest) {
    return api.post<VerifyOrderOTPRequest, VerifyOrderOtpResponse>(
      `/checkout/verify`,
      {
        ...data,
      }
    );
  },

  getStatus: function (data: OrderStatusRequest) {
    return api.get<OrderStatusResponse>(`/${data.transactionId}/status`);
  },
};

export default payments;
