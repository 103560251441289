import { useEffect } from "react";
import { useLocation } from "react-router";
import analyticsManager from "services/analytics-manager";

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    analyticsManager.pageview(location.pathname + location.search);
  }, [location]);
};
