import { transactions } from "apis";

export const API_URLS = {
  common: {
    country: `/torque/country`,
    transactionType: `/torque/transactionType`,
    purposeCode: (id: number) => `/torque/purposeCode/${id}`,
    exchangeRate: `/torque/exchangeRate`,
  },
  invoice: {
    subCustomer: "/torque/subCustomer",
    invoice: `/torque/checkout`,
    invoiceV2: `/torque/checkout/v2`,
    invoiceDetails: `/torque/checkout/details`,
    invoiceItems: `/torque/invoice/invoiceItems`,
    balance: `/torque/balance`,
    deposits: `/torque/deposit`,
  },
  transactions: {
    treasury: `/order`,
    torque: `/torque/order`,
    aggregatedTransactions: `/aggregate/order`,
    reconcile: `/torque/order/reconcile`,
    approval: `/torque/order/approval`,
    transactionDetails: `/transaction`
  },
  user: {
    bankAccounts: `/users/bankAccount/crossBorder`,
    bankAccountStatus: `/users/bankAccount/active`,
    userDetails: `/users/account/activated`,
    alerts: `/users/alerts`,
  },
  invoicing:{
    overview: `/torque/invoice/overview`,
    list: `/torque/invoice`
  }
};
