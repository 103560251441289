import React from "react";
import Lottie from "lottie-react";
import { Page404JSON } from "assets";
import { Button, Text } from "design-system/components/ui";
import { useNavigate } from "react-router";
import { Logo } from "components/Logo";
type Props = {};
const Page404 = (props: Props) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };
  return <div className="w-full h-full container flex flex-col justify-center items-center text-center  gap-2" data-sentry-component="Page404" data-sentry-source-file="index.tsx">
         <div className="flex h-26 shrink-0 items-center pb-3">
              <Logo invert={true} data-sentry-element="Logo" data-sentry-source-file="index.tsx" />
          </div>
      <Lottie animationData={Page404JSON} loop={true} className="w-80 h-80" data-sentry-element="Lottie" data-sentry-source-file="index.tsx" />
      <p className="text-4xl font-bold">Oops!</p>
     
      <Text variant="secondary" size="md" data-sentry-element="Text" data-sentry-source-file="index.tsx">
      The Page you are looking for is not found or might be removed.
      </Text>
      <Button onClick={handleNavigate} data-sentry-element="Button" data-sentry-source-file="index.tsx">Go to Dashboard</Button>
    </div>;
};
export default Page404;