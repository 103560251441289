import { PATH_DASHBOARD } from "router/path";
import { ArrowRightLeft, Bell, Briefcase, LayoutDashboard, LifeBuoy, MenuIcon, Settings, LogOut, X, CircleUser, LineChart, FileText } from "lucide-react";
const sidebarConfig = {
  top: [{
    icon: <LayoutDashboard className="w-5 h-5" />,
    name: "Dashboard",
    path: PATH_DASHBOARD.app.dashboard,
    current: true,
    permissions: [1, 2]
  }, {
    icon: <Briefcase className="w-5 h-5" />,
    name: "My Portfolio",
    path: PATH_DASHBOARD.app.portfolio,
    current: false,
    permissions: [1]
  }, {
    icon: <LineChart className="w-5 h-5" />,
    name: "Mutual Funds",
    path: PATH_DASHBOARD.app.mutualFunds,
    current: false,
    permissions: [1, 2]
  }, {
    icon: <FileText className="w-5 h-5" />,
    name: "Invoicing",
    path: PATH_DASHBOARD.app.invoicing,
    current: false,
    permissions: [1, 2],
    posthogKey: "invoicing-page"
  }, {
    icon: <ArrowRightLeft className="w-5 h-5" />,
    name: "Transactions",
    path: PATH_DASHBOARD.app.transactions,
    current: false,
    permissions: [1, 2]
  }
  // ... other top items
  ],
  bottom: [{
    icon: <LogOut />,
    name: "Log out",
    current: false,
    permissions: [1, 2]
  }]
};
export default sidebarConfig;