const ROOTS_AUTH = "/auth";
const ROOTS_APP = "/app";

function path(root: string, sublink?: string) {
  return `${root}${sublink}`;
}

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  signup: path(ROOTS_AUTH, "/signup"),
  forgotPassword: path(ROOTS_AUTH, "/forgot-password"),
  verify: path(ROOTS_AUTH, "/verify"),
  maintenance: path(ROOTS_AUTH, "/maintenance"),
};

export const PATH_DASHBOARD = {
  root: ROOTS_APP,
  app: {
    dashboard: path(ROOTS_APP, "/dashboard"),
    portfolio: path(ROOTS_APP, "/portfolio"),
    investment_planner: path(ROOTS_APP, "/investment-planner"),
    settings: path(ROOTS_APP, "/settings"),
    profile: path(ROOTS_APP, "/profile"),
    mfa: path(ROOTS_APP, "/mfa"),
    kyc: path(ROOTS_APP, "/kyc"),
    kycAdminPreview: path(ROOTS_APP, "/kyc-admin-preview"),
    transactions: path(ROOTS_APP, "/transactions"),
    mutualFunds: path(ROOTS_APP, "/mutual-funds"),
    invoicing: path(ROOTS_APP, "/invoicing/list"),
    mutualFundsDetails: (mutualFundId: number) =>
      path(ROOTS_APP, `/mutual-fund-details/:${mutualFundId}`),
    portfolioDetails: (portfolioId: number) =>
      path(ROOTS_APP, `/portfolio-details/:${portfolioId}`),
    support: path(ROOTS_APP, `/support`),
    prospects: path(ROOTS_APP, `/prospects`),
    uploadInvoice: path(ROOTS_APP, `/invoice/upload`),
    uploadInvoiceForId: (invoiceId: number) =>
      path(ROOTS_APP, `/invoice/upload/${invoiceId}`),
    createInvoice: path(ROOTS_APP, `/invoice/create`),
    createInvoiceForId: (invoiceId: number) =>
      path(ROOTS_APP, `/invoice/create/${invoiceId}`),
    editInvoice: (invoiceId: number) =>
      path(ROOTS_APP, `/invoice/edit/${invoiceId}`),
    withdrawTransactionList: (currencyCode: string) =>
      path(ROOTS_APP, `/withdraw/${currencyCode}/list`),
  },
  maintenance: path(ROOTS_APP, "/maintenance"),
};
