import { Loader } from "assets";
// import { LinearProgress } from "design-system";
import LinearProgress from "@mui/material/LinearProgress";
import { Box, Stack } from "@mui/material";
import React from "react";
type Props = {};
const LoadingScreen = (props: Props) => {
  return <div className="w-full container flex flex-col items-center justify-center gap-4" style={{
    height: "80vh"
  }} data-sentry-component="LoadingScreen" data-sentry-source-file="index.tsx">
      <Stack sx={{
      width: "40%"
    }} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
        <LinearProgress color="inherit" data-sentry-element="LinearProgress" data-sentry-source-file="index.tsx" />
      </Stack>
    </div>;
};
export default LoadingScreen;