import ReactGA from "react-ga";
import { AnalyticsService } from "./analytics-interface";

class GoogleAnalytics implements AnalyticsService {
  initialize(trackingId: string) {
    ReactGA.initialize(trackingId);
  }

  pageview(path: string) {
    ReactGA.pageview(path);
  }

  event(category: string, action: string, label?: string, value?: number) {
    ReactGA.event({ category, action, label, value });
  }
}

export default GoogleAnalytics;
