import { AxiosError } from "axios";
import Snackbar from "./snackbar";


// Define the error response structure
interface ErrorResponse {
  error: {
    message: string;
  };
}


export const  getAPIError=(error:AxiosError<ErrorResponse>)=>{

  if (error.response && error.response.data && error.response.data.error) {
    const errorMessage = error.response.data.error.message || 'An unknown error occurred';
    Snackbar.error(errorMessage);
  } else {
    // Handle network errors or other unexpected errors
    Snackbar.error('Network error, please try again later.');
  }

  // Reject the promise with the error object
  return Promise.reject(error);
}
