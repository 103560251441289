import { CognitoUserPool } from "amazon-cognito-identity-js";
import AWS from "aws-sdk";

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_CLIENT_ID,
};

const userpool = new CognitoUserPool(poolData);

AWS.config.region = "ap-south-1"; // e.g., 'us-east-1'

const cognitoIdentityCredentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
});

AWS.config.credentials = cognitoIdentityCredentials;

export { userpool, AWS };
