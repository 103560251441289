import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function toCapitalCase(str: string) {
  return str
    .split(" ") // Split the string into words
    .map(
      (word) =>
        word.charAt(0).toUpperCase() + // Capitalize the first letter of each word
        word.slice(1).toLowerCase() // Convert the rest of the word to lowercase
    )
    .join(" "); // Join the words back into a single string
}

export const filterObject = <T extends Record<string, any>>(
  obj: T,
  keys: (keyof T)[]
): Partial<T> => {
  return keys.reduce((acc, key) => {
    if (obj.hasOwnProperty(key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {} as Partial<T>);
};
