import { Fragment, useCallback, useEffect, useState } from "react";
import { Location, useLocation, useNavigate } from "react-router";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightLeft, Bell, Briefcase, LayoutDashboard, LifeBuoy, MenuIcon, Settings, LogOut, X, CircleUser, LineChart, FileText } from "lucide-react";
import { Logo } from "components/Logo";
import { getUserName, logout } from "services/authenticate";
import { Link } from "react-router-dom";
import { PATH_AUTH, PATH_DASHBOARD } from "router/path";
import ProtectedComponent from "components/RBAC/ProtectedComponent";
import { useUser } from "hooks/useUser";
import { useInit } from "hooks/useInit";
import { useFeatureFlagEnabled } from "posthog-js/react";
import FeatureGuard from "components/FeatureGuard";
type MobileSidebarProps = {
  isOpen: boolean;
  onClose: (open: boolean) => void;
  handleNavigateRoute: (path: string) => () => void;
  matchesCurrentRoute: (location: Location, path: string) => boolean;
  sidebarNavigationTop: any;
  sidebarNavigationBottom: any;
};
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

// Extract mobile sidebar content for better organization
const MobileSidebar = ({
  isOpen,
  onClose,
  handleNavigateRoute,
  matchesCurrentRoute,
  sidebarNavigationTop,
  sidebarNavigationBottom
}: MobileSidebarProps) => {
  const location = useLocation();
  return <Transition.Root show={isOpen} as={Fragment} data-sentry-element="unknown" data-sentry-component="MobileSidebar" data-sentry-source-file="MobileSidebar.tsx">
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={onClose} data-sentry-element="Dialog" data-sentry-source-file="MobileSidebar.tsx">
          <Transition.Child as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="MobileSidebar.tsx">
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child as={Fragment} enter="transition ease-in-out duration-300 transform" enterFrom="-translate-x-full" enterTo="translate-x-0" leave="transition ease-in-out duration-300 transform" leaveFrom="translate-x-0" leaveTo="-translate-x-full" data-sentry-element="unknown" data-sentry-source-file="MobileSidebar.tsx">
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1" data-sentry-element="unknown" data-sentry-source-file="MobileSidebar.tsx">
                <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="MobileSidebar.tsx">
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="mt-1 p-2.5" onClick={() => onClose(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <X className="h-6 w-6 text-white" aria-hidden="true" data-sentry-element="X" data-sentry-source-file="MobileSidebar.tsx" />
                    </button>
                  </div>
                </Transition.Child>

                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-dark-black pt-3 px-6 pb-4 ring-1 ring-white/10">
                  <div className="flex h-16 shrink-0 items-center">
                    <Link to={PATH_DASHBOARD.app.dashboard} data-sentry-element="Link" data-sentry-source-file="MobileSidebar.tsx">
                      <Logo data-sentry-element="Logo" data-sentry-source-file="MobileSidebar.tsx" />
                    </Link>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {sidebarNavigationTop.map((item: any) => <FeatureGuard posthogKey={item.posthogKey}>
                            <ProtectedComponent exact={false} requiredPermissions={item.permissions} fallback={<></>}>
  <li key={item.name}>
                              <span onClick={handleNavigateRoute(item?.path)} className={classNames(matchesCurrentRoute(location, item?.path) ? "bg-default-accent text-white" : "text-white hover:text-white hover:bg-black/40", "group flex gap-x-4 rounded-md px-6 py-4 text-sm leading-6 font-semibold cursor-pointer")}>
                                {item.icon}
                                {item.name}
                              </span>
                            </li>
                            </ProtectedComponent>
                            </FeatureGuard>)}
                        </ul>
                      </li>

                      <li className="mt-auto">
                        <ul className="-mx-2 space-y-1">
                          {sidebarNavigationBottom.map((item: any) => <ProtectedComponent exact={false} requiredPermissions={item.permissions} fallback={<></>}>
                              <li key={item.name}>
                                <span onClick={handleNavigateRoute(item?.path)} className={classNames(matchesCurrentRoute(location, item?.path) ? "bg-default-accent text-white" : "text-white hover:text-white hover:bg-black/40", "group flex gap-x-4 rounded-md px-6 py-4 text-sm leading-6 font-semibold cursor-pointer")}>
                                {item.icon}
                                {item.name}
                              </span>
                            </li>
                            </ProtectedComponent>)}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>;
};
export default MobileSidebar;