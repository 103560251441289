import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from "design-system/components/ui/toast";
import { useToast } from "design-system/components/ui/use-toast";
import { Icon } from "@iconify/react";
import { cva, type VariantProps } from "class-variance-authority";
export function Toaster() {
  const {
    toasts
  } = useToast();
  const toastVariant = cva();
  return <ToastProvider data-sentry-element="ToastProvider" data-sentry-component="Toaster" data-sentry-source-file="toaster.tsx">
      {toasts.map(function ({
      id,
      title,
      description,
      variant,
      action,
      ...props
    }) {
      return <Toast key={id} {...props}>
            <div className="flex flex-row items-center gap-x-4">
              <div className="inline-block rounded-md p-1 flex items-center justify-center bg-gray-100">
                <Icon icon="solar:info-square-bold-duotone"
            // className={"text-green-500"}
            height={30} width={30} />
              </div>
              <div className="grid gap-1">
                {title && <ToastTitle>{title}</ToastTitle>}
                {description && <ToastDescription>{description}</ToastDescription>}
              </div>
            </div>

            {action}
            <ToastClose />
          </Toast>;
    })}
      <ToastViewport data-sentry-element="ToastViewport" data-sentry-source-file="toaster.tsx" />
    </ToastProvider>;
}