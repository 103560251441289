import AccountService, { BankAccountStatus } from "apis/account";
import CommonService, { CountriesProps } from "apis/common";
import InvoiceService, { Balance } from "apis/invoice";
import { useUser } from "hooks/useUser";
import { createContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "router/path";
import { BankAccountProps } from "sections/GlobalPayments/global-accounts/GlobalAccounts";
import { getUserName } from "services/authenticate";
import posthog from "posthog-js";
import { PostHogProvider } from 'posthog-js/react';
const posthogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
};
interface InitContextType {
  //   init: Record<string, any>;
  //   setInit: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  countries: CountriesProps[];
  setCountries: React.Dispatch<React.SetStateAction<CountriesProps[]>>;
  bankAccounts: BankAccountProps[];
  setBankAccounts: React.Dispatch<React.SetStateAction<BankAccountProps[]>>;
  getBankAccountDetailsByCountryId: (countryId: number) => BankAccountProps | undefined;
  fetchData: () => void;
  useCountryCode: (countryId: number) => string | undefined;
  isInitialized: boolean;
  cleanUp: () => void;
}
export const InitContext = createContext<InitContextType>({
  //   init: {},
  //   setInit: () => {},
  countries: [],
  setCountries: () => {},
  bankAccounts: [],
  setBankAccounts: () => {},
  getBankAccountDetailsByCountryId: () => undefined,
  fetchData: () => {},
  useCountryCode: () => undefined,
  isInitialized: false,
  cleanUp: () => {}
});
export const InitProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const {
    isAuthenticated
  } = useUser();
  const [isInitialized, setIsInitialized] = useState(false);
  const [countries, setCountries] = useState<CountriesProps[]>([]);
  const [bankAccounts, setBankAccounts] = useState<BankAccountProps[]>([]);

  // console.log("isAuthenticated", isAuthenticated, email);

  useEffect(() => {
    if (isAuthenticated) {
      const initialize = async () => {
        await fetchData();
        setIsInitialized(true);
      };
      initialize();
    }
  }, [isAuthenticated]);
  const useCountryCode = (countryId: number): string | undefined => {
    return useMemo(() => {
      const country = countries.find(c => c.id === countryId);
      return country?.currencySymbol;
    }, [countries, countryId]);
  };
  const fetchData = async () => {
    try {
      const countryResponse = await CommonService.GetCountries();
      const countries = countryResponse.data?.data;
      setCountries(countries);

      // console.log("countries -----", countries);

      const balanceResponse = await InvoiceService.GetBalance();
      const balances = Array.isArray(balanceResponse.data.available) ? balanceResponse.data.available : [balanceResponse.data.available];
      const bankAccountsResponse = await AccountService.getBankAccountStatus();
      const bankAccounts = bankAccountsResponse.data.data;
      const mergedData = mergeData(countries, balances, bankAccounts);
      // console.log("mergedData", mergedData);
      // @ts-ignore
      setBankAccounts(mergedData);
      if (window.location.pathname == PATH_DASHBOARD.maintenance) {
        window.location.href = PATH_DASHBOARD.app.dashboard;
      }
    } catch (err: any) {
      console.log("Error:", err);
      if (err.response.status === 401 && window.location.pathname !== PATH_DASHBOARD.maintenance) {
        window.location.href = PATH_DASHBOARD.maintenance;
      }
    } finally {}
  };

  // 2. Memoize the mergeData function
  const mergeData = useMemo(() => (countries: CountriesProps[], balances: Balance[], bankAccounts: BankAccountStatus[]) => {
    return bankAccounts.map(bankAccount => ({
      ...countries.find(ba => ba.id === bankAccount.countryId),
      ...balances.find(b => b.countryId === bankAccount?.countryId),
      ...bankAccount
    }));
  }, []);

  //utils

  //Get Bank Account DetailsBy Country Id
  const getBankAccountDetailsByCountryId = (countryId?: number) => {
    return bankAccounts.find(b => b.countryId === countryId);
  };
  const cleanUp = () => {
    setIsInitialized(false);
    setCountries([]);
    setBankAccounts([]);
  };
  return <PostHogProvider options={posthogOptions} apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} data-sentry-element="PostHogProvider" data-sentry-component="InitProvider" data-sentry-source-file="InitProvider.tsx">
    <InitContext.Provider value={{
      //   init,
      //   setInit,
      countries,
      setCountries,
      bankAccounts,
      setBankAccounts,
      getBankAccountDetailsByCountryId,
      fetchData,
      useCountryCode,
      isInitialized,
      cleanUp
    }} data-sentry-element="unknown" data-sentry-source-file="InitProvider.tsx">
      {children}
    </InitContext.Provider>
    </PostHogProvider>;
};