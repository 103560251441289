import { useIsMobile } from '../hooks/useIsMobile';
import { useState, useEffect } from 'react';
const MobileWarningBanner = () => {
  const isMobile = useIsMobile();
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Check if user has previously dismissed the banner
    const hasSeenWarning = localStorage.getItem('mobileWarningDismissed');
    setIsVisible(isMobile && !hasSeenWarning);
  }, [isMobile]);
  const handleDismiss = () => {
    localStorage.setItem('mobileWarningDismissed', 'true');
    setIsVisible(false);
  };
  if (!isVisible) return null;
  return <div className="fixed bottom-0 left-0 right-0 bg-yellow-50 text-yellow-800 px-4 py-3 text-center z-50 shadow-md border-b border-yellow-100" data-sentry-component="MobileWarningBanner" data-sentry-source-file="MobileWarningBanner.tsx">
      <div className="flex flex-col items-center justify-center gap-2 text-sm">
        <span role="img" aria-label="warning">⚠️</span>
        <p>
          This application is optimized for desktop viewing. 
          For the best experience, please use a larger screen.
        </p>
        <button onClick={handleDismiss} className="ml-4 px-3 py-1 bg-yellow-100 hover:bg-yellow-200 text-yellow-800 rounded-md transition-colors duration-200">
          Got it
        </button>
      </div>
    </div>;
};
export default MobileWarningBanner;