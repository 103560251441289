import React, { ReactNode, useRef } from "react";
import { SnackbarProvider as NotistackProvider, SnackbarKey } from "notistack";
import { Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import { Collapse } from "@mui/material";
interface Props {
  children?: ReactNode;
}
export default function SnackbarProvider({
  children
}: Props) {
  const notistackRef = useRef<NotistackProvider | null>(null);
  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };
  return <div data-sentry-component="SnackbarProvider" data-sentry-source-file="SnackbarProvider.tsx">
      <NotistackProvider ref={notistackRef} maxSnack={5} preventDuplicate autoHideDuration={2000} variant="success" TransitionComponent={Collapse} anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }} action={key => <button onClick={onClose(key)} className="text-gray-500 hover:text-gray-700 p-1 focus:outline-none">
            <Icon icon="eva:close-fill" />
          </button>} iconVariant={{
      info: <SnackbarIcon icon="eva:info-fill" color="blue" />,
      success: <SnackbarIcon icon="eva:checkmark-circle-2-fill" color="green" />,
      warning: <SnackbarIcon icon="eva:alert-triangle-fill" color="yellow" />,
      error: <SnackbarIcon icon="eva:alert-circle-fill" color="red" />
    }} data-sentry-element="NotistackProvider" data-sentry-source-file="SnackbarProvider.tsx">
        {children}
      </NotistackProvider>
    </div>;
}
interface SnackbarIconProps {
  icon: string;
  color: string;
}
function SnackbarIcon({
  icon,
  color
}: SnackbarIconProps) {
  return <div className={`flex items-center justify-center text-${color}-500 bg-${color}-100 w-[40px] h-[40px] mr-2 rounded rounded-xl`} data-sentry-component="SnackbarIcon" data-sentry-source-file="SnackbarProvider.tsx">
      <Icon icon={icon} width={22} data-sentry-element="Icon" data-sentry-source-file="SnackbarProvider.tsx" />
    </div>;
}