import {ThemeConfig} from 'antd'
import { theme } from './theme'


export const AntdThemeConfig:ThemeConfig={
    token:{
        colorPrimary:theme.palette.primary,
        borderRadius:4
    },
    components:{
        Collapse:{
            contentBg:'#ffffff',
            headerBg:'#ffffff',
            colorBorder:"#ffffff"
        }
    }
}