import React from 'react';
import { hasPermission } from './hasPermission';
import { useUser } from 'hooks/useUser';
import { useInit } from 'hooks/useInit';
import LoadingScreen from 'components/LoadingScreen';
type ProtectedProps = {
  requiredPermissions: number[];
  children: React.ReactNode;
  fallback?: React.ReactNode;
  isLoading?: boolean;
  loadingFallback?: React.ReactNode;
  exact?: boolean;
};
const ProtectedComponent: React.FC<ProtectedProps> = ({
  requiredPermissions,
  children,
  fallback,
  isLoading,
  loadingFallback = <LoadingScreen />,
  exact = true
}) => {
  const {
    permissions
  } = useUser();
  // const { isLoading } = useInit();
  return hasPermission(permissions, requiredPermissions, exact) && !isLoading ? <>{children}</> :
  // isLoading ? loadingFallback : 
  fallback || null;
};
export default ProtectedComponent;