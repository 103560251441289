import { ComponentType } from "react";
import { Navigate } from "react-router-dom";
import { setAuthorizationHeader } from "utils/api";
import { PATH_AUTH } from "./path";
import { usePageTracking } from "hooks/usePageTracking";
const PrivateRouter = ({
  Element
}: {
  Element: ComponentType;
}) => {
  usePageTracking();
  const token = localStorage.getItem("token");
  if (token) {
    setAuthorizationHeader(token);
    return <Element />;
  }
  return <Navigate to={PATH_AUTH.login} data-sentry-element="Navigate" data-sentry-component="PrivateRouter" data-sentry-source-file="private_router.tsx" />;
};
export default PrivateRouter;