import { InitContext } from "context/InitProvider";
import { useContext } from "react";

export const useInit = () => {
  const context = useContext(InitContext);

  if (!context)
    throw new Error("useInit context must be use inside InitProvider");

  return context;
};
