import { ComingSoon } from "components/ComingSoon";
import LoadingScreen from "components/LoadingScreen";
import { useInit } from "hooks/useInit";
import { ComponentType, Suspense, lazy } from "react";
export const Loadable = <P extends object,>(Component: ComponentType<P>): React.FC<P> => {
  const WrappedComponent: React.FC<P> = props => {
    return <Suspense fallback={<LoadingScreen />} data-sentry-element="Suspense" data-sentry-component="WrappedComponent" data-sentry-source-file="elements.tsx">
      {<Component {...props} />}
    </Suspense>;
  };
  WrappedComponent.displayName = `Loadable(${Component.displayName || Component.name || "Component"})`;
  return WrappedComponent;
};

//AUTH
export const LoginPage = Loadable(lazy(() => import("pages/auth/Login")));
export const ForgotPasswordPage = Loadable(lazy(() => import("pages/auth/ForgotPassword")));
export const SignupPage = Loadable(lazy(() => import("pages/auth/Signup")));
export const OTPVerificationPage = Loadable(lazy(() => import("pages/auth/OTPVerification")));

//APP
export const DashboardPage = Loadable(lazy(() => import("pages/app/MainDashboard")));
export const PortfolioPage = Loadable(lazy(() => import("pages/app/Portfolio")));
export const PortfolioDetailsPage = Loadable(lazy(() => import("pages/app/PortfolioDetails")));
export const SettingsPage = Loadable(lazy(() => import("pages/app/Settings")));
export const MutualFundsPage = Loadable(lazy(() => import("pages/app/MutualFunds")));
export const MutualFundDetailsPage = Loadable(lazy(() => import("pages/app/MutualFundDetails")));
export const TransactionsPage = Loadable(lazy(() => import("pages/app/Transactions")));
export const KYCPage = Loadable(lazy(() => import("pages/app/KYC")));
export const KYCAdminPreviewPage = Loadable(lazy(() => import("pages/app/KYCAdminPreview")));
export const MFAPage = Loadable(lazy(() => import("pages/app/MFA")));
export const UploadInvoice = Loadable(lazy(() => import("pages/app/Invoice/UploadInvoice")));
export const EditInvoice = Loadable(lazy(() => import("pages/app/Invoice/EditInvoice")));
export const CreateInvoice = Loadable(lazy(() => import("pages/app/Invoice/CreateInvoice")));
export const WithdrawTransactionsList = Loadable(lazy(() => import("pages/app/Withdraw")));
export const InvoicingPage = Loadable(lazy(() => import("pages/app/Invoicing/Invoicing")));
export const UserProfilePage = Loadable(lazy(() => import("pages/app/UserProfile/UserProfile")));
export const MaintenancePage = Loadable(lazy(() => import("pages/misc/MaintainancePage")));
export const ComingSoonPage = ComingSoon;