import { Navigate, useRoutes } from "react-router-dom";
import { ComingSoonPage, DashboardPage, ForgotPasswordPage, KYCAdminPreviewPage, KYCPage, LoginPage, MFAPage, MutualFundDetailsPage, MutualFundsPage, OTPVerificationPage, PortfolioDetailsPage, PortfolioPage, SettingsPage, SignupPage, TransactionsPage, UploadInvoice, EditInvoice, WithdrawTransactionsList, CreateInvoice, InvoicingPage, UserProfilePage, MaintenancePage } from "./elements";
import PublicRouter from "./public_router";
import PrivateRouter from "./private_router";
import { Sidebar } from "components/Sidebar";
import { sentryCreateBrowserRouter } from "utils/sentry";
import Page404 from "components/Page404";
export default function Router() {
  return useRoutes([{
    path: "auth",
    children: [{
      path: "login",
      index: true,
      element: <PublicRouter Element={LoginPage} />
    }, {
      path: "verify",
      element: <PublicRouter Element={OTPVerificationPage} />
    }, {
      path: "signup",
      element: <PublicRouter Element={SignupPage} />
    }, {
      path: "forgot-password",
      element: <PublicRouter Element={ForgotPasswordPage} />
    }]
  }, {
    path: "app",
    children: [{
      path: "dashboard",
      element: <PrivateRouter Element={DashboardPage} />
    }, {
      path: "mfa",
      element: <PublicRouter Element={MFAPage} />
    }, {
      path: "investment-planner",
      element: <PrivateRouter Element={() => <Sidebar>
                  <ComingSoonPage />
                </Sidebar>} />
    }, {
      path: "portfolio",
      element: <PrivateRouter Element={PortfolioPage} />
    }, {
      path: "portfolio/:portfolioId",
      element: <PrivateRouter Element={PortfolioDetailsPage} />
    }, {
      path: "mutual-funds",
      element: <PrivateRouter Element={MutualFundsPage} />
    }, {
      path: "mutual-funds/:mutualFundId",
      element: <PrivateRouter Element={MutualFundDetailsPage} />
    }, {
      path: "kyc",
      element: <PrivateRouter Element={KYCPage} />
    }, {
      path: "kyc-admin-preview",
      element: <PrivateRouter Element={KYCAdminPreviewPage} />
    }, {
      path: "prospects",
      element: <PrivateRouter Element={() => <Sidebar>
                  <ComingSoonPage />
                </Sidebar>} />
    }, {
      path: "transactions",
      element: <PrivateRouter Element={TransactionsPage} />
    }, {
      path: "support",
      element: <PrivateRouter Element={() => <Sidebar>
                  <ComingSoonPage />
                </Sidebar>} />
    }, {
      path: "settings",
      element: <PrivateRouter Element={SettingsPage} />
    }, {
      path: "profile",
      element: <PrivateRouter Element={UserProfilePage} />
    }, {
      path: "invoice",
      children: [{
        path: "upload",
        element: <PrivateRouter Element={UploadInvoice} />
      }, {
        path: "upload/:invoiceId",
        element: <PrivateRouter Element={UploadInvoice} />
      }, {
        path: "create",
        element: <PrivateRouter Element={CreateInvoice} />
      }, {
        path: "create/:invoiceId",
        element: <PrivateRouter Element={CreateInvoice} />
      }, {
        path: "edit/:invoiceId",
        element: <PrivateRouter Element={EditInvoice} />
      }]
    }, {
      path: "invoicing",
      children: [{
        path: "list",
        element: <PrivateRouter Element={InvoicingPage} />
      }]
    }, {
      path: "withdraw/:currencyCode/list",
      element: <PrivateRouter Element={WithdrawTransactionsList} />
    }, {
      path: "maintenance",
      element: <MaintenancePage />
    }]
  }, {
    children: [{
      path: "*",
      element: <Page404 />
    }, {
      path: "maintenance",
      element: <MaintenancePage />
    }]
  }, {
    element: <PublicRouter Element={LoginPage} />,
    index: true
  }, {
    path: "*",
    element: <Navigate to="/404" replace />
  }]);
}