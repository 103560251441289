import account from "./account";
import auth from "./auth";
import invest from "./invest";
import payment from "./payment";
import funds from "./funds";
import transactions from "./transactions";
import { QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient();

export { account,  auth, invest, payment, funds, transactions, queryClient };
