import { UserContext, UserContextValue } from "context/UserProvider";
import { useContext } from "react";

// Create a custom hook to use the invoice context
export const useUser = (): UserContextValue => {
    const context = useContext(UserContext);
    if (context === undefined) {
      throw new Error("useUser must be used within an UserProvider");
    }
    return context;
  };
  