import React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "design-system/utils";
import { Text, textVariants, TextProps } from "./text";
export interface ListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  id?: string;
  icon?: React.ReactNode;
  leftHeading?: string;
  leftSubHeading?: string;
  rightHeading?: string;
  rightSubHeading?: string;
  leftHeadingVariant?: TextProps;
  leftSubHeadingVariant?: TextProps;
  rightHeadingVariant?: TextProps;
  rightSubHeadingVariant?: TextProps;
  className?: string;
  leftHeadingClassName?: string;
  leftSubHeadingClassName?: string;
  rightHeadingClassName?: string;
  rightSubHeadingClassName?: string;
}
const ListItem: React.FC<ListItemProps> = ({
  id,
  icon,
  leftHeading,
  leftSubHeading,
  rightHeading,
  rightSubHeading,
  leftHeadingVariant,
  leftSubHeadingVariant,
  rightHeadingVariant = {
    variant: "secondary"
  },
  rightSubHeadingVariant,
  leftHeadingClassName,
  leftSubHeadingClassName,
  rightHeadingClassName,
  rightSubHeadingClassName,
  className,
  ...props
}) => {
  return <li key={id} className={cn("flex justify-between gap-x-6 py-5", className)} {...props} data-sentry-component="ListItem" data-sentry-source-file="list-item.tsx">
      <div className="flex min-w-0 gap-x-4">
        {icon && <div className="flex-shrink-0">{icon}</div>}
        <div className="min-w-0 flex-auto">
          {leftHeading && <Text {...leftHeadingVariant} className={cn(leftHeadingClassName)}>
              {leftHeading}
            </Text>}
          {leftSubHeading && <Text {...leftSubHeadingVariant} className={cn(leftSubHeadingClassName)}>
              {leftSubHeading}
            </Text>}
        </div>
      </div>
      <div className="flex shrink-0 flex-col items-end">
        {rightHeading && <Text {...rightHeadingVariant} className={cn(rightHeadingClassName)}>
            {rightHeading}
          </Text>}
        {rightSubHeading && <Text {...rightSubHeadingVariant} className={cn(rightSubHeadingClassName)}>
            {rightSubHeading}
          </Text>}
      </div>
    </li>;
};
export { ListItem };