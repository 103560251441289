import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "design-system/utils";
const labelVariants = cva("flex items-center justify-center space-x-2 py-1 px-3 rounded-md", {
  variants: {
    variant: {
      default: "bg-gray-100  text-gray-500",
      destructive: "bg-red-100  text-red-500",
      success: "bg-green-100  text-green-500",
      warning: "bg-orange-100  text-orange-500",
      info: "bg-blue-100   text-blue-500"
    },
    size: {
      xs: "text-xs",
      sm: "text-sm",
      md: "text-base",
      lg: "text-lg",
      xlg: "text-2xl"
    }
  },
  defaultVariants: {
    variant: "default",
    size: "xs"
  }
});
export interface LabelProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof labelVariants> {
  title: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
}
const Label = React.forwardRef<HTMLDivElement, LabelProps>(({
  className,
  variant = "default",
  title,
  startIcon,
  endIcon,
  size,
  fullWidth = false,
  ...props
}, ref) => <div ref={ref} className={cn(labelVariants({
  variant,
  size,
  className
}), fullWidth ? "w-full" : "")} {...props}>
      {startIcon}
      <span className="font-medium">{title}</span>
      {endIcon}
    </div>);
Label.displayName = "Label";
export default Label;