import { api } from "utils/api";

export type FundMetaData = {
  imageUrl: string;
  description: string;
  shortDescription: string;
  cagr: number;
  fundsInvolved: Record<string, Record<string, string>>;
  riskIndex: number;
  liquidityIndex: number;
  minInvestment: number;
  exitLoad: number;
  lockInPeriod: number;
  minRedeem: number;
};

export type FundReturn = {
  year: number;
  month: number;
  returns: number;
};

export type Fund = {
  name: string;
  tags: string[];
  metaData: FundMetaData;
  schemeCd: string;
  folioNumber: string;
  categoryId: number;
  graph: FundReturn[];
};

export type FetchFundResponse = {
  httpStatus: "OK";
  data: Record<string, Fund>;
};

export const funds = {
  fetchFunds: async function () {
    const res = await api.get<FetchFundResponse>(`/funds`);
    return res?.data?.data;
  },
};

export default funds;
